<template>
  <div id="ArticleDetail">
    <div>
      <div class="header">
        <div>{{articleDetail.title}}</div>
        <div>
          <span>原创</span><span style="margin-right:0.2rem">{{articleDetail.author}}</span><span>{{Global.fromatTimestamp(articleDetail.created_at).split(' ')[0]}}</span><span class="tag" v-if="articleDetail.tag">{{articleDetail.tag}}</span>
        </div>
        <div >{{articleDetail.summary}}</div>
      </div>
      <img class="thnm" v-if="articleDetail.thumbimgurl" referrer="no-referrer" :src="Global.changeImg(articleDetail.thumbimgurl)" />
      <div class="content"  v-html="articleDetail.content">
      </div>
      <div class="good">
        <span @click="toLike(articleDetail.id)">
          <van-icon v-if="articleDetail.upvote.length" color="#4c9bff" name="good-job-o" style="margin:0.02rem 0.05rem 0 0.13rem;font-size:0.14rem" />
          <van-icon v-else  name="good-job-o" style="margin:0.02rem 0.05rem 0 0.13rem;font-size:0.14rem" />
          {{articleDetail.upvote_count}}
        </span>
      </div>
    </div>
    <div class="bottom">
      <div>相关推荐</div>
      <div class="listThree">
        <div class="list" @click.stop="toLink(i.category_id,i.id)" v-for="(i,item) in recommend" :key="item">
          <div>
            <div >{{i.title}}</div>
            <div>
              {{i.title}}
            </div>
            <div>
              <span>{{Global.clearWhite($moment(new Date(Date.parse(i.created_at))).fromNow())}}</span>
              <span><span><van-icon name="eye-o" style="margin:0.02rem 0.05rem 0 0.13rem;font-size:0.14rem" /></span>{{i.views}}</span>
            </div>
          </div>
          <img  v-if="i.thumbimgurl" :src="Global.changeImg(i.thumbimgurl)" />
          <img v-else src="../../assets/image/home/index1.png"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ArticleDetail',
   data(){
    return{
      articleDetail:'',
      categoryId:'',//分类id
      articleId:'',//文章id
      recommend:'',//相关推荐
      
    }
  },
  mounted(){
    this.first()
  },
  methods:{
    toLike(articleId){
      this.axios({
        method: 'PATCH',
        url:'/article/articleUpvote?status='+this.status+'&articleId='+articleId,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          if(this.status == 0){
            this.$toast('取消点赞')
            this.status = 1
            this.articleDetail.upvote_count--
            this.articleDetail.upvote.length = 0
          }else{
            this.$toast('点赞成功')
            this.status = 0
            this.articleDetail.upvote_count++
            this.articleDetail.upvote.length = 1
          }
        }
      })
    },
    first(){
      this.categoryId=this.$route.query.categoryId
      this.articleId=this.$route.query.articleId
      this.axios({
        method: 'GET',
        url:'/article/getArticleDetail?categoryId='+this.categoryId+'&articleId='+this.articleId,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then((res) => {
        if(res.data.code == 0){
          this.articleDetail = res.data.data.detail
          this.recommend = res.data.data.recommend
          if(this.articleDetail.upvote.length){
            this.status = 0
          }else{
            this.status = 1
          }
        }
      })
    },
    toLink(categoryId,id){
      this.axios({
        method: 'PATCH',
        url: '/article/addArticleViews/'+id,
        headers: {'Authorization': `Bearer ${this.Global.getCookie('tokenDkhNww')}`},
      }).then(() => {
        this.$router.push('/ArticleDetail?categoryId='+categoryId+'&articleId='+id)
      }) 
    },
  },
  watch: {
    '$route': 'first'
  }
}
</script>
<style>
.content div,.content span,.content p{
  color: #F6F7FD;
  font-size: 0.13rem !important;
  line-height: 0.24rem;
}
.content img{
  width: 100%;
}
</style>
<style lang="less" scoped>
#ArticleDetail{
  .thnm{
    width: 100%;
  }
  .bottom{
    .listThree{
      padding: 0 0.15rem;
      background: #23252E;
      .blue{
        color: #3890FF;
      }
      >div:last-child{
        border: none;
      }
      >div{
        padding: 0.15rem 0;
        display: flex;
        align-items: center;
        border-bottom:.1px solid #5C5D6E61;
        >img{
          max-width: 1.03rem;
          width: 0.86rem;
          margin-left: 0.16rem;
        }
        >div{
          display: flex;
          flex-direction: column;
          flex: 1;
          overflow: hidden;
          color: #F6F7FD;
          >div:last-child{
            display: flex;
            align-items: center;
            justify-content: space-between;
            >span:first-child{
              color: #989AB1;
              font-size: 0.12rem;
            }
            >span:last-child{
              color: #FFFFFF;
              font-size: 0.12rem;
              display: flex;
              align-items: center;
              color: #989AB1;
            }
            >span.active{
              background: #9091A3;
            }
            >span.eye{
              background: none;
              color: #989AB1;
              transform: none;
            }
          }
          >div:nth-child(2){
            color: #989AB1;
            font-size: 0.12rem;
            align-items: center;
            margin: 0.08rem 0 0.08rem;
          }
          >div:first-child{
            color: #F6F7FD;
            font-size: 0.14rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
    }
    >div:first-child{
      padding: 0.15rem;
      border-bottom: 1px solid #5C5D6E;
      color: #F6F7FD;
      font-size: 0.16rem;
    }
    padding: 0 0 0.15rem;
    margin-top: 0.11rem;
  }
  .good{
    display: flex;
    justify-content: flex-end;
    >span{
      color: #a4a5b8;
      font-size: 0.12rem;
    }
  }
  >img{
    width: 100%;
    margin-bottom: 0.2rem;
  }
  .header{
    >div:last-child{
      background: #3D404F;
      padding: 0 0.13rem;
      color: #989AB1;
      font-size: 0.12rem;
      line-height: 0.24rem;
      margin-bottom: 0.15rem;
    }
    >div:nth-child(2){
      margin: 0.1rem 0 0.2rem;
      display: flex;
      align-content: center;
      >span{
        color: #989AB1;
        font-size: 0.12rem;
        display: block;
        line-height: 0.18rem;
      }
      >span.tag{
        padding: 0 0.05rem;
        font-size: 0.12rem;
        background: #3D404F;
        color: #9091A3;
        height: 0.18rem;
        border-radius: 0.02rem;
        margin-right: 0.065rem;
        margin-left: 0.07rem;
      }
    }
    >div:first-child{
      color: #F6F7FD;
      line-height: 0.23rem;
      font-size: 0.16rem;
    }
  }
  >div{
    padding: 0.15rem;
    background: #23252E;
  }
  background: #16171D;
}
</style>